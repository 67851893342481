import Image from "next/image";
import { checkUrlType } from "@lib/ads";
import { classNames } from "@helpers/helper";

type BannerProps = {
  image: string;
  activeSlide: number;
  currentSlide: number;
  title: string;
  description: string;
  buttonText?: string;
  buttonUrl?: string | UrlLabelType;
  index: number;
  previous?: () => void;
  next: () => void;
};

export const CardType1: React.FC<BannerProps> = ({
  image,
  title,
  description,
  buttonText,
  buttonUrl,
  index,
}) => {
  return (
    <div
      className={classNames(
        "tw-relative tw-mx-1 tw-w-[350px] lg:tw-w-[416px] tw-h-[300pxpx] lg:tw-h-[265px] tw-rounded-2xl adscardtype",
        "hover:tw-shadow-2xl hover:tw-scale-105 hover:tw-z-10 tw-transform hover:-tw-translate-y-4 tw-transition-transform tw-duration-300 tw-ease-in-out tw-overflow-hidden hover:tw-bg-opacity-10 "
      )}
    >
      <div className="tw-w-full tw-flex tw-flex-col lg:tw-flex-row tw-items-center">
        <div
          className={classNames(
            " button-background tw-w-full lg:tw-w-[70%] tw-rounded-t-2xl lg:tw-rounded-se-none lg:tw-rounded-s-2xl tw-h-[150px] lg:tw-h-[265px]  tw-opacity-[1] tw-pl-8 tw-pr-6 tw-inline-flex tw-items-center ",
            `promotion-bg-${index + 1}`
          )}
        >
          <div className="tw-text-white ">
            <h1 className="tw-text-lg lg:tw-text-2xl lg:tw-text-[29px] tw-font-normal tw-px-4">
              {title}
            </h1>
            <h2 className="tw-px-4 tw-text-lg lg:tw-text-2xl lg:tw-text-[29px] tw-font-medium">
              {description}
            </h2>
            <button
              onClick={() => {
                checkUrlType(buttonUrl);
              }}
              className="promotion-ad-btn tw-text-white lg:tw-text-black lg:tw-bg-white tw-font-bold tw-inline-flex tw-items-center lg:tw-mx-4 tw-min-w-28 lg:tw-min-w-24  lg:tw-my-4 tw-rounded-full tw-py-1 px-3 tw-align-middle tw-justify-between "
            >
              {buttonText}{" "}
              <Image
                src="/images/hotel-detail/arrowW.svg"
                alt="arrow"
                width={20}
                height={15}
                className=" w-arrow tw-transform  tw-rotate-180 tw-inline-block lg:tw-hidden "
              />
              <Image
                src="/images/hotel-detail/arrowB.svg"
                alt="arrow"
                width={20}
                height={15}
                className="b-arrow tw-transform  tw-rotate-180 lg:tw-inline-block tw-hidden "
              />
            </button>
          </div>
        </div>
        <div className=" tw-relative tw-w-full lg:tw-w-[30%] tw-h-[150px] lg:tw-h-[265px] tw-flex">
          <Image
            src={image}
            alt="image"
            width={150}
            height={265}
            layout="fill"
            className="tw-rounded-b-2xl lg:tw-rounded-none lg:tw-rounded-e-2xl hover:tw-scale-110 tw-transition-transform tw-duration-300 tw-ease-in-out"
            objectFit="cover"
            objectPosition={"center"}
          />
        </div>
      </div>
    </div>
  );
};
