import adsConfigJson from "@data/pagesConfigAds";
import { IMAGE_PATH } from "@helpers/config";
import { checkCookies, getCookie } from "cookies-next";

const getTodayDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = String(today.getMonth() + 1); // Months start at 0!
  let dd = String(today.getDate());

  if (Number(dd) < 10) dd = "0" + dd;
  if (Number(mm) < 10) mm = "0" + mm;

  const formattedToday = `${yyyy}-${mm}-${dd}`;

  return formattedToday;
};
const getTypeConfig = (productType: string, section = "/", type = "banner") => {
  try {
    const productConfig = adsConfigJson.find((f) => f.product == productType);
    const sectionConfig = productConfig?.sections?.find(
      (f) => f.name == section
    );

    const space = sectionConfig?.spaces.find((f) => f.name == type);

    const one = space?.config.find((f) => f);
    if (one) {
      return {
        type: one?.type,
        priority: one?.priority,
        qty: space?.config?.length,
      };
    }
  } catch (error) {
    console.log("🚀 ~ file: index.ts:37 ~ error:", error);
  }
  return {
    type: ["banner"],
    priority: "revenue",
    qty: 3,
  };
};

export const validateImageUrl = (imgUrl: string | string[], folder = "") => {
  let img = "";

  if (typeof imgUrl === "string") {
    img = imgUrl;
  } else if (typeof imgUrl === "object") {
    img = imgUrl[0];
  }

  if (img === "undefined") {
    return "/images/modalSignInSignUp/photoNotFound.png";
  }
  /*if (img.substring(img.length - 1)=="/") {
    return "/images/modalSignInSignUp/photoNotFound.png";
  }*/

  let firstLetters = img?.substring(0, 3);
  if (firstLetters?.includes("/im")) {
    return img;
  }

  if (firstLetters?.includes("//")) {
    return `https:${img}`;
  }

  if (firstLetters?.includes("htt")) {
    return img;
  }
  return `${IMAGE_PATH}${folder}${img}`;
};

export const pathDictionary = (path: string) => {
  if (path === "/") {
    return "home";
  }

  if (path === "/flight") {
    return "home";
  }
  if (path === "/cars") {
    return "home";
  }
  if (path === "/holidaystays") {
    return "home";
  }
  if (path === "/activities") {
    return "home";
  }
  if (path === "/transfers") {
    return "home";
  }
  if (path === "/cruises") {
    return "home";
  }
  if (path === "/weeks") {
    return "home";
  }
  if (path === "/dreamtrips") {
    return "home";
  }
  let splitPath = path.split("/");
  return splitPath[1];
};

export const getRequestBanner = (
  orgId: number,
  productType: string,
  section: string,
  pathName: string
) => {
  const typeReq = getTypeConfig(productType, section);

  return {
    currency: getCookie("currency_code") || "USD",
    dateStart: getTodayDate(),
    from: "context",
    lang: getCookie("lan_code") || "en-US",
    orderBy: "",
    orgId: orgId,
    pathName,
    searchLat: 0,
    searchLong: 0,
    priority: typeReq.priority,
    qty: typeReq.qty,
    type: typeReq.type,
  };
};

export const getRequestPreLoginAds = (
  pathName: string,
  orgId: number,
  productType: string
) => {
  // const typeReq = getTypeConfig(productType, section);

  return {
    currency: getCookie("currency_code") || "USD",
    dateStart: getTodayDate(),
    from: "context",
    lang: getCookie("lan_code") || "en-US",
    orderBy: "",
    orgId: orgId,
    pathName,
    searchLat: 0,
    searchLong: 0,
    priority: "revenue",
    qty: 6,
    type: [productType],
  };
};

export const textValidation = (textValue: string | JsonType | undefined) => {
  let lanCode = String(getCookie("lan_code"));
  let code = checkCookies("lan_code") ? lanCode.split("-")[0] : "en";
  var urlRedirect: string | undefined = "";
  if (textValue) {
    if (typeof textValue === "object") {
      for (let [key, value] of Object.entries(textValue)) {
        if (key === code) {
          urlRedirect = value;
        }
      }

      if (!urlRedirect && Object.keys(textValue).length > 0) {
        urlRedirect = textValue[Object.keys(textValue)[0]];
      }
    } else {
      urlRedirect = textValue;
    }
  }
  return urlRedirect;
};

export const urlBodyV2Button = (
  url: string | AdsBannerUrl | UrlLabelType | UrlMultiLangV2[]
) => {
  let lanCode = String(getCookie("lan_code"));
  let code = checkCookies("lan_code") ? lanCode.split("-")[0] : "en";
  let buttonInfo = undefined;

  if (Array.isArray(url) && url.length > 0) {
    buttonInfo = url.find((f) => f.code === code);

    if (!buttonInfo) {
      buttonInfo = url[0];
    }
  } else if (typeof url === "object") {
    const aux: any = url;
    // object to array
    let defaultValue;
    for (let [key, value] of Object.entries(aux)) {
      if (key === code) {
        buttonInfo = value;
      }
      if (!defaultValue) {
        defaultValue = value;
      }
    }
    return { url: String(buttonInfo || defaultValue), text: "", code };
  }
  return buttonInfo;
};

export const handleButtonTxt = (txt: string | JsonType | undefined) => {
  let lanCode = String(getCookie("lan_code"));
  let code = checkCookies("lan_code") ? lanCode.split("-")[0] : "en";
  var retText: string | undefined = "";

  if (txt) {
    if (typeof txt === "object") {
      for (let [key, value] of Object.entries(txt)) {
        if (key === code) {
          // findUrl = true;
          retText = value;
        }
      }
    } else {
      retText = txt;
    }
  }

  return retText;
};

export const checkUrlType = (urlObj?: string | UrlLabelType | AdsBannerUrl) => {
  const isLooged = checkCookies("userToken");
  let lanCode = String(getCookie("lan_code"));
  let code = checkCookies("lan_code") ? lanCode.split("-")[0] : "en";
  var urlRedirect = "";
  if (urlObj) {
    if (typeof urlObj === "object") {
      for (let [key, value] of Object.entries(urlObj)) {
        if (key === code) {
          urlRedirect = value;
        }
      }
    } else {
      urlRedirect = urlObj;
    }
  }

  if (urlRedirect === "") {
    if (typeof urlObj === "object" && !Array.isArray(urlObj)) {
      // console.log("response URL to redirect bol", urlObj?.en);
      urlRedirect = urlObj?.en;
    }
  }

  console.log("checkurl", urlObj);
  if (urlRedirect.includes("resortId=IRIS")) {
    urlRedirect = urlRedirect.replace(/resorts/g, "stays");
  }
  // return;
  // urlRedirect =
  //   "https://www.google.com/?first_name=<<first_name>>&last_name=<<last_name>>&token=<<token>>&checkIn=<<checkin>>&checkOut=<<checkout>>&site=42";
  // let urlRedirect2 = urlRedirect;

  // const isFilledUrl = urlRedirect.match(MATCH_URL_SPECIAL_PARAMS_WITH_ANGLE);
  // if (checkCookies('userToken')) {
  //   if (Array.isArray(isFilledUrl) && isFilledUrl.length > 0) {
  //     let tmpUrlRedirect = getUserInfoByKey(
  //       urlRedirect,
  //       isFilledUrl,
  //       currentUser
  //     );
  //     if (tmpUrlRedirect) {
  //       urlRedirect = tmpUrlRedirect;
  //     }
  //   }
  // }

  let firstLetters = urlRedirect.substring(0, 3);
  if (firstLetters.includes("htt")) {
    window.location.href = urlRedirect;
  } else if (firstLetters.includes("ww")) {
    window.location.href = `https://${urlRedirect}`;
  } else {
    if (isLooged) {
      window.location.href = `${addRoot(urlRedirect)}`;
    }
    // no redirect
    return null;
  }
};

const addRoot = (url: string) => {
  if (url.substring(0, 1) === "/") {
    return url;
  }
  return `/${url}`;
};
