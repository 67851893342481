import Slider from "react-slick";
import { useRef, useState } from "react";
import { CardType1 } from "./CardType1";
import { useAdsToShow } from "@hooks/useAds";
import {
  handleButtonTxt,
  textValidation,
  urlBodyV2Button,
  validateImageUrl,
} from "@lib/ads";
import Image from "next/image";
import { classNames } from "@helpers/helper";
import { useWhiteLabelLan } from "@hooks/useLayout";

type BannerProps = {
  section?: string;
  space?: string;
};

export const CardCenterV2: React.FC<BannerProps> = ({
  section = "hotels",
  space = "space6",
}) => {
  const { getLangLabel } = useWhiteLabelLan();
  const { data, isLoading } = useAdsToShow(section, space);
  let sliderRef: any = useRef<any>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const next = () => {
    sliderRef?.slickNext();
  };
  const previous = () => {
    sliderRef?.slickPrev();
  };
  function PrevArrow(props: any) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className={classNames(
          " prev-arrow-wso",
          " tw-w-12 lg:-tw-w-[65px] tw-h-auto",
          " !tw-z-40 tw-border-none tw-shadow-4xl  tw-bg-white tw-absolute tw-top-1/2 tw-rounded-full tw-align-middle tw-left-0 lg:tw-left-[-65px] tw-transform tw--translate-y-1/2 tw-inline-flex tw-items-center tw-justify-center",
          " tw-ml-[-1rem] md:tw-ml-[-1.5rem] ",
          " lg: tw-ml-[-1.5rem]"
        )}
      >
        <Image
          src="/images/hotel-detail/gallery-popup-icons/arrow.svg"
          width={65}
          height={63}
          alt="arrow"
          className="tw-transform tw-self-center !tw-z-40 hover:tw-scale-110 tw-transition-transform tw-duration-300 tw-ease-in-out"
        />
      </button>
    );
  }

  function NextArrow(props: any) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className={classNames(
          " next-arrow-wso",
          " tw-w-12 lg:-tw-w-[65px] tw-h-auto",
          " !tw-z-40 tw-border-none tw-shadow-4xl tw-rotate-180  tw-bg-white tw-absolute tw-top-1/2 tw-rounded-full tw-align-middle tw-right-4 lg:tw-right-[-65px] tw-transform tw--translate-y-1/2 tw-inline-flex tw-items-center tw-justify-center",
          " tw-right-[-1.5rem]",
          " md:tw-relative md:tw-mt-[-16.5rem] md:tw-left-[97%]",
          " lg:tw-absolute lg:tw-left-auto lg:tw-mt-auto"
        )}
      >
        <Image
          src="/images/hotel-detail/gallery-popup-icons/arrow.svg"
          width={65}
          height={63}
          alt="arrow"
          className="tw-transform tw-self-center !tw-z-40 hover:tw-scale-110 tw-transition-transform tw-duration-300 tw-ease-in-out"
        />
      </button>
    );
  }
  const settings = {
    centerMode: false,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    addaptiveHeight: true,
    variableWidth: true,
    afterChange: (current: number) => setActiveSlide(current),
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          addaptiveHeight: true,
          variableWidth: false,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          addaptiveHeight: true,
          variableWidth: true,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          addaptiveHeight: true,
          variableWidth: true,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          addaptiveHeight: true,
          variableWidth: true,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          addaptiveHeight: true,
          variableWidth: true,
          centerMode: false,
          infinite: true,
        },
      },
    ],
  };

  if (isLoading) return <></>;
  if (!data || (Array.isArray(data) && data.length == 0)) return <></>;

  return (
    <section className="tw-pt-[3rem]  tw-w-full tw-flex tw-flex-col tw-self-center tw-justify-center tw-items-center section-wso sectionads">
      <header className="tw-w-full tw-tracking-[1.55px]">
        <h1 className="homev2-title tw-text-black tw-uppercase  tw-text-xl lg:tw-text-[31px] tw-font-bold  lg:tw-text-3xl">
          {getLangLabel("THIS WEEK SPECIAL OFFERS")}
        </h1>
        <h2 className="homev2-subtitle tw-text-gray-600 tw-font-medium tw-text-xl tw-hidden lg:tw-inline-flex">
          {getLangLabel("Special deals from our partners that you can´t miss")}
        </h2>
      </header>
      <div className="slider-container tw-text-black tw-w-full [&>div>div>div]:tw-inline-flex  [&>div>div]:tw-overflow-x-clip [&>div>div]:tw-overflow-y-visible tw-mt-6">
        <Slider
          ref={(slider: any) => {
            sliderRef = slider;
          }}
          {...settings}
        >
          {data.map((item, index) => {
            let title: any;
            let subtitle: any;
            if (item?.title) {
              title = item?.title;
            }
            if (item?.subtitle) {
              subtitle = item?.subtitle;
            }

            let buttonText = "";
            let buttonUrl;
            if (item?.buttonText) {
              buttonText = handleButtonTxt(item?.buttonText) || "";
              buttonUrl = item?.url || "";
            } else {
              buttonText = urlBodyV2Button(item?.url)?.text || "";
              buttonUrl = urlBodyV2Button(item?.url)?.url || "";
              buttonUrl = buttonUrl || "";
            }

            return (
              <CardType1
                key={`center-item-${index}`}
                image={validateImageUrl(item.image)}
                activeSlide={activeSlide}
                currentSlide={index}
                title={textValidation(title) || ""}
                description={textValidation(subtitle) || ""}
                buttonText={buttonText}
                buttonUrl={buttonUrl}
                next={next}
                previous={previous}
                index={index}
              />
            );
          })}
        </Slider>
      </div>
    </section>
  );
};
