import Image from "next/image";
import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";
import { useRouter } from "next/router";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { PriceGuaranteeAd } from "../PriceGuaranteeAd";

type BannerProps = {};

export const Guarantee: React.FC<BannerProps> = ({}) => {
  const { getLangLabel } = useWhiteLabelLan();
  const router = useRouter();
  const { enablePriceGuaranteeAd, priceGuaranteeAdVersion } = useLayoutStore(
    (state) => ({
      enablePriceGuaranteeAd: state.externalConfig?.enablePriceGuaranteeAd,
      priceGuaranteeAdVersion: state.externalConfig?.priceGuaranteeAdVersion,
    }),
    shallow
  );

  if (
    !enablePriceGuaranteeAd ||
    !["/home", "/home/resorts", "/home/stays"].includes(router.pathname)
  ) {
    return <></>;
  }

  if (priceGuaranteeAdVersion === "v2") {
    return <PriceGuaranteeAd />;
  }

  return (
    <section className="homev2-guarantee tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-bg-black tw-text-white tw-min-h-[500px] tw-mt-20">
      <div className="tw-w-full md:tw-w-3/5 tw-flex tw-flex-col tw-h-[500px] tw-justify-center tw-items-start tw-px-10 lg:tw-px-24">
        <h1 className=" tw-uppercase tw-text-2xl lg:tw-text-7xl tw-font-normal tw-leading-10 tw-tracking-widest">
          {getLangLabel("booking with 110% guarantee")}
        </h1>
        <h2 className="tw-font-normal tw-text-lg lg:tw-text-3xl lg:tw-text-[28px] tw-leading-10 tw-pt-6 tw-tracking-widest">
          {getLangLabel(
            "Find a price lower that ours, we will give you back 110% of the difference"
          )}
        </h2>
        <button
          onClick={() => {
            window.open("https://promos.network/priceguarantee", "_blank");
          }}
          className=" tw-tracking-widest tw-bg-transparent tw-font-bold tw-inline-flex tw-items-center tw-text-white tw-text-xl tw-mt-4 tw-rounded-md tw-align-middle tw-justify-center tw-h-10"
        >
          {getLangLabel("More Info")}{" "}
          <Image
            src="/images/hotel-detail/arrowW.svg"
            alt="arrow"
            width={50}
            height={20}
            className="tw-transform tw-self-center tw-rotate-180"
          />
        </button>
      </div>
      <div className="tw-w-full md:tw-w-2/5 tw-h-[500px] tw-flex tw-justify-center tw-items-center tw-relative">
        <Image
          src="/images/Ads/maldives.png"
          alt="100% Satisfaction Guarantee"
          className="tw-w-full tw-h-full tw-object-cover tw-object-center hover:tw-scale-110 tw-transition-transform tw-duration-300 tw-ease-in-out"
          width={500}
          height={500}
          layout="fill"
        />
      </div>
    </section>
  );
};
